import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom'; 

import logo from '../Img/nelogo.jpg';

function BasicExample() {
  return (

    <div className='navigation'>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>

              <NavDropdown title="About" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/About">About Hospital</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Manager">Why Choose </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Department" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/Treat">Treatments</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Emergncy">Emergency 24*7</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Pathology">Pathology</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Pharmacy">Pharmacy</NavDropdown.Item>
              
              </NavDropdown>

              <Nav.Link as={Link} to="/Doctor"> Our Doctor</Nav.Link>

              <Nav.Link as={Link} to="/Gallery">Gallery</Nav.Link>

              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

              <div className='appoint'>
                <Nav.Link as={Link} to="/Appoint" style={{ color: 'black' }}>  Appointment</Nav.Link>
              </div>

              {/* <div className='appoint2'>
                <a href=''><i class="fa fa-facebook-f"></i></a>
              </div>

              <div className='appoint2'>
                <a href=''><i class="fa fa-instagram"></i></a>
              </div> */}

              <div className='appoint2'>
                <a href=''><i class="fa fa-youtube-play"></i></a>
              </div>

              <div className='appoint2'>
              <a href="https://api.whatsapp.com/send?phone=9140262702" target="_blank"><i class="fa fa-whatsapp"></i></a>

              </div>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default BasicExample;
