import React from 'react'
import Footer from '../Components/Footer'


const Pathology = () => {
    return (
        <>
            <div className='emergency'>

                <h1>Pathology</h1><br />
                <p>Pathology is the branch of medical science that involves the study and diagnosis of diseases through the examination of tissues, organs, bodily fluids, and whole bodies (autopsies). Pathologists are medical doctors who specialize in pathology and are trained to interpret laboratory tests and analyze the results to provide information about the nature and causes of diseases.</p>
                

            </div>

            <Footer />

        </>
    )
}

export default Pathology