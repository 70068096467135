import React from 'react'


import Footer from '../Components/Footer';
import Depart from '../Components/Depart';

const Treat = () => {
  return (
    <>
      <Depart />
      <Footer />

    </>
  )
}

export default Treat
