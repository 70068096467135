import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='foot'>
                <h1>Urmila Hospital</h1>
                <p>Urmila Hospital in Aliganj, Lucknow is a top player in the category Hospitals in the Lucknow. This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Lucknow. Over the course of its journey, this business has established a firm foothold in it's industry. The belief that customer satisfaction is as important as their products and services,</p>
            </div>
            <div className='foot'>
                <h1>Quick Link</h1><br /><br />
                <Link to="/" className='link'>Home</Link><br />
                <Link to="/about" className='link'>About</Link><br />
                <Link to="/Manager" className='link'>Why Choose</Link><br />
                <Link to="/Gallery" className='link'>Gallery</Link><br />
                <Link to="/contact" className='link'>Contact</Link>
            </div>
            <div className='foot'>
                <h1>Useful  Link</h1>
                <br /><br />
                <Link to="/Treat" className='link'> Treatments</Link><br />
                <Link to="/Emergncy" className='link'>Emergency</Link><br />
                <Link to="/Pathology" className='link'>Pathology</Link><br />
                <Link to="/Pharmacy" className='link'>Pharmacy</Link><br />
                <Link to="/Doctor" className='link'>Doctor</Link>
            </div>

            <div className='foot'>
                <h1>Reach Us </h1>
                <p>2/6B Priyadarshini Colony Sitapur Road Aliganj Lucknow</p>
                <p>0522-3585006 9455020306 9119992821</p>
                <p>9999257520</p>
                <p>drankushmisra@gmail.com</p>

                <a href="https://api.whatsapp.com/send?phone=9140262702" target="_blank"><i class="fa fa-whatsapp"></i></a>


            </div>
        </div>
    )
}

export default Footer