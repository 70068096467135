import React from 'react'
import Ankus from '../Components/Ankus'
import Footer from '../Components/Footer'

const Manager = () => {
  return (
   <>
   
   <Ankus/>
   <Footer/>
   </>
  )
}

export default Manager