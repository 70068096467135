import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';
import About from './Pages/About';
import Contact from './Pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from './Components/Head';
import Home from './Components/Abouts';

import Manager from './Pages/Manager'
import Gallery from './Pages/Gallery'
import Treat from './Pages/Treat';
import Appoint from './Pages/Appoint'
import Emergncy from './Pages/Emergncy'
import Pathology from './Pages/Pathology'
import Pharmacy from './Pages/Pharmacy'
import Doctor from './Pages/Doctor'

import Desiese from './Pages/Desiese'
import Lapro from './Pages/Lapro'
import Urologist from './Pages/Urologist';
import Procto from './Pages/Procto';
import Plastic from './Pages/Plastic';
import Neuro from './Pages/Neuro'
import Pediasurgery from './Pages/Pediasurgery';
import Generalmedicine from './Pages/Generalmedicine';
import Pediatric from './Pages/Pediatric';
import Gastro from './Pages/Gastro';
import Orthopadics from './Pages/Orthopadics';
import Ent from './Pages/Ent';



function App() {
  return (

    <div className='mains'>


      <BrowserRouter basename=''>
        {/* <Head /> */}
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          {/* <Route path="/Home" element={<Home />} /> */}
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Manager" element={<Manager />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Treat" element={<Treat />} />
          <Route path="/Appoint" element={<Appoint />} />
          <Route path="/Emergncy" element={<Emergncy />} />
          <Route path="/Pathology" element={<Pathology />} />
          <Route path="/Pharmacy" element={<Pharmacy />} />
          <Route path="/Doctor" element={<Doctor />} />


          <Route path="/Desiese" element={<Desiese />} />
          <Route path="/Lapro" element={<Lapro />} />
          <Route path="/Urologist" element={<Urologist />} />
          <Route path="/Procto" element={<Procto />} />
          <Route path="/Plastic" element={<Plastic />} />
          <Route path="/Neuro" element={<Neuro />} />
          <Route path="/Pediasurgery" element={<Pediasurgery />} />
          <Route path="/Generalmedicine" element={<Generalmedicine />} />
          <Route path="/Pediatric" element={<Pediatric />} />
          <Route path="/Gastro" element={<Gastro />} />
          <Route path="/Orthopadics" element={<Orthopadics />} />
          <Route path="/Ent" element={<Ent />} />



        </Routes>

      </BrowserRouter>


    </div>


  );
}

export default App;
