import React from 'react'
import Footer from '../Components/Footer'

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const Contact = () => {
  return (
    <>
      <div className='contact'>

        <div className='contact2'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.0322367432764!2d80.938339!3d26.902472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995634339bea9f%3A0xec519161d5ab8b75!2sUrmila%20Hospital!5e0!3m2!1sen!2sin!4v1708614165074!5m2!1sen!2sin" style={{width:'100%',height:'100%'}}></iframe>
        </div>


        <div className='contact2'>
          <Form>
            <Row className="mb-3">

            <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="Name" placeholder="Name" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control placeholder="enter address" />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formGridcontact">
              <Form.Label>contact</Form.Label>
              <Form.Control placeholder="contact" />
            </Form.Group>

            

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>Gender</Form.Label>
                <Form.Control />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>service</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Age</Form.Label>
                <Form.Control />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>

      </div>



      <Footer />
    </>
  )
}

export default Contact