import React from 'react'
import Slid from '../Img/gastro.jpg'

import Gynea from '../Img/gynae.jpg'
import Uro from '../Img/urology.jpg'
import Lapro from '../Img/lapro.jpg'
import procto from '../Img/procto.jpg'
import plast from '../Img/plastic.jpg'
import Neuro from '../Img/neuro.jpg'
import Pedia from '../Img/pedia.jpg'
import genrl from '../Img/General Medicine.png'
import trick from '../Img/pediatric.jpg'
import gasto from '../Img/gastro.jpg'
import ortho  from '../Img/orthopadic.jpg'
import ent from '../Img/ent.jpg'





import { Link } from 'react-router-dom';


const Depart = () => {
    return (


        <div className='depart'>

            <center><h1>Treatments</h1></center>
            <div className='depart3'>

                <div className='face' >
                    <Link to="/Desiese" style={{ textDecoration: 'none' }}>
                        <h1>Gynaecology</h1>
                        <img src={Gynea} />
                    </Link>

                </div>

                <div className='face'>
                    <Link to="/Urologist" style={{ textDecoration: 'none' }}>
                        <h1>Urology</h1>
                        <img src={Uro} />
                    </Link>
                </div>


                <div className='face'>
                    <Link to="/Lapro" style={{ textDecoration: 'none' }}>
                        <h1>Laparoscopic Surgery</h1>
                        <img src={Lapro} />
                    </Link>
                </div>


            </div>


            <div className='depart3'>

                <div className='face' >
                    <Link to="/Procto" style={{ textDecoration: 'none' }}>
                        <h1>Proctology</h1>
                        <img src={procto} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Plastic" style={{ textDecoration: 'none' }}>
                        <h1>Plastic Surgery</h1>
                        <img src={plast} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Neuro" style={{ textDecoration: 'none' }}>
                        <h1>Neurosurgery</h1>
                        <img src={Neuro} />
                    </Link>
                </div>


            </div>



            <div className='depart3'>

                <div className='face' >
                    <Link to="/Pediasurgery" style={{ textDecoration: 'none' }}>
                        <h1>Paediatric Surgery</h1>
                        <img src={Pedia} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Generalmedicine" style={{ textDecoration: 'none' }}>
                        <h1>General  Medicine</h1>
                        <img src={genrl} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Pediatric" style={{ textDecoration: 'none' }}>
                        <h1>Paediatric</h1>
                        <img src={trick} />
                    </Link>
                </div>
                
            </div>


            <div className='depart3'>

                <div className='face' >
                    <Link to="/Gastro" style={{ textDecoration: 'none' }}>
                        <h1>Gastro</h1>
                        <img src={gasto} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Orthopaedics" style={{ textDecoration: 'none' }}>
                        <h1>Orthopaedics</h1>
                        <img src={ortho} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Ent" style={{ textDecoration: 'none' }}>
                        <h1>E.N.T.</h1>
                        <img src={ent} />
                    </Link>
                </div>

            </div>


            <div className='depart3'>

                <div className='face' >
                    <Link to="/Pharmacy" style={{ textDecoration: 'none' }}>
                        <h1>Pharmacy</h1>
                        <img src={genrl} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/Pathology" style={{ textDecoration: 'none' }}>
                        <h1>Pathology</h1>
                        <img src={genrl} />
                    </Link>
                </div>

                <div className='face'>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <h1>Ultrasound X-ray</h1>
                        <img src={genrl} />
                    </Link>
                </div>

            </div>



        </div>
    )
}

export default Depart