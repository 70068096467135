import React from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/orthopadic.jpg'

const Orthopadics = () => {
    return (
        <>
            <br />
            <center><h1>Orthopaedics</h1></center>
            <center>
            <p>Dr. Alpesh Shukla</p>
          <p>Dr. Sulabh Mehrotra</p>
            </center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Trauma Surgery </b>   Orthopedic surgeons treat fractures, dislocations, and other traumatic injuries to the bones and joints, often working in emergency settings.</p>
                    <p><b>Joint Replacement Surgery: </b> Involves the replacement of damaged joints, commonly the hip, knee, and shoulder, with artificial implants to relieve pain and improve function.</p>
                    <p><b>Spine Surgery:</b>   Orthopedic spine surgeons address conditions affecting the spine, such as herniated discs, spinal stenosis, and deformities like scoliosis.</p>
                    <p><b>Foot and Ankle Surgery:</b>  Orthopedic surgeons may address conditions such as fractures, arthritis, and deformities in the foot and ankle.</p>



                </div>

                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Orthopadics