import React from 'react'
import Header from './Header'
import Abouts from './Abouts'
import Depart from './Depart'
import Ankus from './Ankus'
import Footer from './Footer'
import Question from './Question'



const Layout = () => {
  return (

    <div className='mains'>
      <Header />
      <Abouts />
      <Depart />
     
      <Ankus />
      <Question/>
      <Footer/>

     
   
    </div>



  )
}

export default Layout