import React, { useState } from 'react';
import Footer from '../Components/Footer';

const Appoint = () => {
  // State variables to store form data
  const [patientName, setPatientName] = useState('');
  const [Address, setAddress] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // You can add your logic to handle the form data here
    console.log('Form submitted:', { patientName, Address, appointmentDate });
  };

  return (
    <>
      <div className='appointment'>
        <h2>Doctor Appointment Form</h2>
        <form onSubmit={handleFormSubmit}>
          <label>
            Patient Name:
            <input type='text' value={patientName} onChange={(e) => setPatientName(e.target.value)} />
          </label>

          <br />

          <label>
           Address:
            <input type='text' value={Address} onChange={(e) => setAddress(e.target.value)} />
          </label>

          <br />

          <label>
            Appointment Date:
            <input type='date' value={appointmentDate} onChange={(e) => setAppointmentDate(e.target.value)} />
          </label>

          <br />

          <button type='submit'>Submit Appointment</button>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Appoint;
