import React from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/pediatric.jpg'

const Pediatric = () => {
  return (
    <>
    <br />
    <center><h1>Paediatric</h1></center>
    <center>

    <p>Dr. Monika Chopra, MD</p>
          <p>Dr. Manish, MD</p>
    </center>

    <div className='gyne'>

        <div className='gyne2'>
            <p><b>Preventive Care: </b> Pediatricians focus on preventive healthcare, including well-child check-ups, vaccinations, and screenings to ensure the healthy development of children.</p>
            <p><b>Developmental Milestones:</b> Pediatricians monitor and assess the physical, emotional, and social development of children, identifying and addressing any developmental delays or concerns.</p>
            <p><b>Nutrition and Growth: </b> Pediatricians provide guidance on nutrition, growth, and overall health to ensure that children are meeting appropriate developmental milestones.</p>
           
            <p><b>Childhood Injuries: </b>Pediatricians manage injuries and accidents that commonly occur during childhood, providing care for fractures, burns, and other traumas.</p>
            <p><b>Nutrition and Growth: </b> Behavioral and Mental Health:</p>

        </div>

        <div className='gyne2'>
            <img src={Gynae} />
        </div>

    </div>

    <Footer />

</>
  )
}

export default Pediatric