import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import slide1 from '../Img/poster3.jpg';
import slide2 from '../Img/healthcare_banner.jpg';
import slide3 from '../Img/ayus.jpg';
import slide4 from '../Img/General Medicine.png'

import Backs from '../Img/backs.png'

const Header = () => {


  
  return (
    <div className='header'  >
      

      <div className='header2'>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={slide1}
              alt="First slide"
            />

          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={slide2}
              alt="Second slide"
            />

          </Carousel.Item>


          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={slide3}
              alt="Third slide"
            />
          </Carousel.Item>


          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={slide4}
              alt="Third slide"
            />
          </Carousel.Item>




        </Carousel>
      </div>

      


    </div>
  );
};

export default Header;
