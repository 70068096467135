import React from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/pedia.jpg'

const Pediasurgery = () => {
  return (
    <>
    <br />
    <center><h1>Paediatric Surgery</h1></center>
    <center> <p> Dr. JD Ravat, MCH </p>
          <p>Dr. Sunil Kanaujia MCH</p></center>

    <div className='gyne'>

        <div className='gyne2'>
            <p><b>Neonatal Surgery </b> Involves surgical interventions on newborns, often addressing congenital anomalies or conditions that require immediate attention shortly after birth.</p>
            <p><b>Pediatric General Surgery:</b> Encompasses a broad range of surgical procedures to treat conditions such as hernias, appendicitis, abdominal tumors, and gastrointestinal disorders in children.</p>
            <p><b>Pediatric Urology:</b> Focuses on surgical conditions related to the urinary tract and genitalia in children, including hypospadias repair, undescended testes, and urinary tract anomalies.</p>
            <p><b>Pediatric Orthopedic Surgery: </b> Specializes in the treatment of musculoskeletal conditions in children, including congenital deformities, fractures, and developmental disorders like scoliosis.</p>
            <p><b>Pediatric Neurosurgery:    </b> Addresses surgical issues related to the nervous system in children, including brain and spinal cord tumors, congenital anomalies, and neurological trauma.</p>

        </div>

        <div className='gyne2'>
            <img src={Gynae} />
        </div>

    </div>

    <Footer />

</>
  )
}

export default Pediasurgery

