import React from 'react';

import pic1 from '../Img/poster.jpg'
import pic2 from '../Img/front.jpg'



const Abouts = () => {


    return (
        <div className='abouts'>

            <div className='abouts2'>
                <h1>Welcome to Urmila Hospital</h1>
                <p>Urmila Hospital in Aliganj, Lucknow is a top player in the category Hospitals in the Lucknow.
                    This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Lucknow.
                    Over the course of its journey, this business has established a firm foothold in it's industry.
                    The belief that customer satisfaction is as important as their products and services,
                </p>

                <p> It is an effortless task in commuting to this establishment as there
                    are various modes of transport readily available. It is at Sitapur Road, Near St Joseph School, Near Tadikhana,
                    Priyadershni Colony, which makes it easy for first-time visitors in locating this establishment.
                </p>
            </div>



            <div className='abouts3'>
                {/* 
                <div className='aboutpic'>
                    <img src={pic1} />
                </div>

                <div className='aboutpic'>
                    <img src={pic2} />
                </div>

                <div className='aboutpic'>
                    <img src={pic1} />
                </div> */}


                <img src={pic2} />

            </div>



        </div>
    )
}

export default Abouts