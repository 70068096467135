import React from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/procto.jpg'


const Procto = () => {
  return (
    <>
            <br />
            <center><h1>Proctology</h1></center>
            <center>
            <p> Dr. Ankush Misra, MS, FIAGES, FALS </p>
          <p>Dr. KK Agarwal, MS</p>
            </center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Hemorrhoids </b>Swollen blood vessels in the rectum or anus that can cause discomfort, bleeding, and itching.</p>
                    <p><b>Anal Fissures:</b>Small tears or cracks in the lining of the anus, which can lead to pain and bleeding during bowel movements.</p>
                    <p><b>Anal Abscesses and Fistulas</b>  Infections or abnormal passageways that may form between the anal canal and the skin.</p>
                    <p><b>Inflammatory Bowel Disease (IBD):</b>Conditions such as Crohn's disease and ulcerative colitis that cause inflammation in the digestive tract.</p>
                    <p><b>Polyps: </b>Abnormal growths in the lining of the colon or rectum that can potentially develop into cancer.</p>

                </div>


                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>
  )
}

export default Procto