import React from 'react'
import Ankus from '../Components/Ankus'
import Footer from '../Components/Footer'
import Abouts from '../Components/Abouts'


const About = () => {
  return (
    <>
 
   <Abouts/>
    <Footer/>
    
    </>
  )
}

export default About