import React from 'react'
import Footer from '../Components/Footer'

const Pharmacy = () => {
  return (
    <>
    <div className='emergency'>
        <h1>Pharmacy (Medical Store )</h1><br />
        <p>Pharmacy is a branch of health sciences that focuses on the preparation, dispensing, and proper utilization of medications. Pharmacists, who are healthcare professionals trained in pharmacy, play a crucial role in ensuring the safe and effective use of medications. Here are some key aspects of pharmacy:</p>

        <p><b>Dispensing Medications: </b> Pharmacists are responsible for dispensing prescription and over-the-counter medications to patients based on the instructions provided by healthcare providers.</p>
        <p><b>Hospital Pharmacy</b> Pharmacists in hospital settings collaborate with healthcare teams to manage medications for inpatients, oversee medication distribution systems, and provide expertise in drug therapy.</p>

    </div>
    <Footer />

</>
  )
}

export default Pharmacy