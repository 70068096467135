import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Question = () => {
    return (



        <>

            <br /><br /><br />
            <center><h1>Frequently Asked Question</h1></center>
            <br />

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>1. What is the average consulting fee at Urmila Hospital in Lucknow?</Accordion.Header>
                    <Accordion.Body>
                        For the average consulting fees at Urmila Hospital, it is advisable to get in touch with them during their working hours from Monday:-
                        Open 24 Hrs, Tuesday: Open 24 Hrs, Wednesday: Open 24 Hrs, thu:- Open 24 Hrs, Friday: Open 24 Hrs, Saturday: Open 24 Hrs,
                        Sunday: Open 24 Hrs
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>2. Is Urmila Hospital clean and hygienic?</Accordion.Header>
                    <Accordion.Body>
                        Almost all hospitals maintain high-level cleanliness to keep patients safe from any infection. For more information on the cleanliness maintained at Urmila Hospital, you can check the customer ratings and reviews or visit the hospital in person.
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>3. Do hospitals provide ambulance services?</Accordion.Header>
                    <Accordion.Body>
                    Yes, most hospitals provide ambulance services for transporting patients to the hospital and back. However, it is recommended to check with Urmila Hospital for further information on this.
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>4. Can I visit a patient any time of the day in a hospital?</Accordion.Header>
                    <Accordion.Body>
                    No, there are fixed visiting hours at hospitals. You can contact the Urmila Hospital for visiting hours.
                    </Accordion.Body>
                </Accordion.Item>


            </Accordion>


        </>
    )
}

export default Question