import React from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/plastic.jpg'


const Plastic = () => {
    return (
        <>
            <br />
            <center><h1>Plastic Surgery</h1></center>
            <center>
            <p> Dr. Vivek Gupta, MCH </p>
            </center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Reconstructive Surgery: </b>Purpose: Primarily focuses on correcting defects or abnormalities resulting from congenital conditions, trauma, tumors, infections, or developmental issues.</p>
                    <p><b>Aesthetic or Cosmetic Surgery:</b>Facelift, rhinoplasty (nose job), breast augmentation, liposuction, and abdominoplasty (tummy tuck).</p>
                    <p><b>Rhinoplasty</b>  Nose reshaping surgery to enhance facial harmony.</p>
                    <p><b>Ethical and Psychological Aspects:</b> Plastic surgery raises ethical considerations, and it is important to assess psychological factors, ensuring patients have realistic expectations.</p>
                    <p><b>Risks and Complications:  </b>All surgeries carry some level of risk, and potential complications vary depending on the procedure.</p>

                </div>

                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Plastic